@import '../../theme/base.scss';

.buttons-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.sticky-button-bar {
  border-top: solid 2px $border-color;
  padding: 1rem;
  background-color: white;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.button-link {
  border: none;
  background: none;
  color: $sub-header-color;
  text-decoration: underline;
  outline: none;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: $header-color;
  }
}

.icon-button {
  @extend .button-link;
  text-decoration: none;
  font-size: 12px;
}
