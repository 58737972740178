@import '../../theme/base.scss';

.list-container {
  list-style: none;
  font-size: 16px;
  color: $sub-header-color;
  text-transform: capitalize;
}

.list-item {
  .delete-item-button {
    visibility: hidden;
    margin-left: 15px;
  }
  &:hover {
    .delete-item-button {
      visibility: initial;
    }
  }
}


.clickable-item {
  box-sizing: content-box;
  padding: 0 10px;
  &:hover {
    border-right: $on-hover-side-borders;
    border-left: $on-hover-side-borders;
    cursor: pointer;
    color: black !important;
  }
}

.empty-list-message {
  padding: 10px 15px;
  text-transform: none;
}
