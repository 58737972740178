@import '../../theme/base.scss';

.dialog-container {
  >div:nth-child(2) {
    >div{
      overflow: visible !important;
    }
  }
}

.dialog-title {
  background: #fafafa;
  margin: 0;
  padding: 10px 1rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h2 {
    color: $header-color;
    font-weight: normal;
    text-transform: capitalize;
  }
  h3, h4 {
    margin: 0;
    color: $header-color;
  }
}

.dialog-title-notes {
  @extend .dialog-title;
  padding: 0 1rem;
  padding-bottom: 10px;
}
