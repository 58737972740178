@import '../../theme/base.scss';

.form-container {
  position: relative;
  padding: 1rem 0;
  padding-bottom: 0;
  width: 100%;
  button {
    align-self: flex-end;
  }
}

.form-row{
  display: flex;
  align-items: center;
  >div {
    flex: 1;
  }
  >div + div {
    margin: 0;
    margin-left: 15px;
  }
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.inline-radio {
  div[role="radiogroup"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.small-form-field {
  max-width: 150px;
}

.remove-top-margins {
  padding-top: 0;
}

.align-right {
  align-items: flex-end;
  text-align: right;
}

.MuiFormControl-marginNormal {
  margin: 0;
}
