// Discreet Palette
// Palette generated here https://mycolor.space/?hex=%2336AB91&sub=1

$primary-color: #36AB91;
$primary-overlay: rgba($primary-color, 0.5);

$secondary-color: #E7FEF7;
$secondary-overlay: rgba($secondary-color, 0.5);

$body-back-color: #fafafa;
$header-color: rgb(60, 64, 67);
$sub-header-color: rgb(112, 117, 122);
$border-color: rgb(218, 220, 224);

$sucess-color: #00A984;
$error-color: #FFA17A;

$on-hover-side-borders: solid 5px $primary-color;

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
  font-weight: lighter;
}

// LAYOUT
.vertical-margin {
  margin-top: 2rem;
  margin-bottom: 2rem
}
.full-width {
  width: 100%;
  flex-grow: 2 !important;
}
