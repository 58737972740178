.menu {
  display: inline-table !important;
}

.menu a {
  margin-left: 15px;
  font-size: 16px;
}

.title {
  flex: 1;
}
